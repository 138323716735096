.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.give_value {
  font-weight: 600;
  color: #666;
}
.yd-cell:after {
  margin-left: 0.24rem;
}
#presented {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.4rem 0.2rem;
}
#presented .ios-sty {
  margin-top: 0.1rem;
}
#presented .cell {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0.3rem 0.3rem 0.1rem;
  border-bottom: 1px solid #e1e1e1;
}
#presented .cell input {
  width: 60%;
}
#presented .cell::-webkit-input-placeholder {
  color: #b3b3b3;
}
#presented .btn-con {
  margin-top: 1rem;
}
